import React from 'react';
import { Link, graphql } from 'gatsby';

import {
    IntroHolder,
    TwoColumns,
    Sidebar,
    Reference,
    IntroQuote,
    CenteredQuote,
} from '@bahaide/shared/components/InnerPage';

import InnerPage from '../../../components/InnerPage';

const BahaullahUndSeinAnspruch = ({ data }) => (
    <InnerPage title="Bahá’u’lláh und Sein Anspruch" description="">
        <IntroHolder
            title="Bahá’u’lláh und Sein Anspruch"
            subtitle="Bahá’u’lláh und Sein Anspruch als Überbringer einer göttlichen Botschaft"
            picture={data.introImage}
            breadcrumbs={{
                parent: 'Woran Bahá’í glauben',
                'current-item': 'Bahá’u’lláh und Sein Anspruch',
            }}
        >
            <IntroQuote href="/woran-bahai-glauben/bahaullah-und-sein-anspruch/zitate">
                Der wesentliche Zweck von Religion und Glaube ist, das innere
                Sein des Menschen durch die Ausgießungen himmlischer Gnade zu
                veredeln.
            </IntroQuote>
        </IntroHolder>
        <TwoColumns>
            <div id="content">
                <p>
                    Heute geltende, ethisch-moralische Grundsätze sind ohne die
                    Lehren der großen Religionen nicht denkbar. Ihr Zweck ist
                    es, das menschliche Verhalten und die zwischenmenschlichen
                    Beziehungen zu fördern und das Wohlergehen der Menschheit
                    insgesamt zu entwickeln.
                </p>
                <div>
                    <p>
                        Erscheint ein neuer{' '}
                        <Link to="/woran-bahai-glauben/gott-und-der-mensch/fortschreitende-offenbarung/">
                            Gottesoffenbarer
                        </Link>
                        , erfährt die Menschheit einen göttlichen Impuls für
                        ihre weitere Entwicklung. Er wird von Gott gerufen, Ihm
                        als Sein Sprachrohr zu dienen. Zu diesen auserwählten
                        Menschen zählen Moses, der vor dem brennenden Busch
                        stand, Buddha, der unter dem Bodhi-Baum erleuchtet
                        wurde, Jesus Christus, auf den der Heilige Geist in Form
                        einer Taube herniederkam, oder Muhammad, dem der
                        Erzengel Gabriel erschien.
                    </p>
                    <p>
                        In der Mitte des 19. Jahrhunderts erwarteten Menschen
                        der verschiedensten Religionen das Kommen eines
                        Verheißenen. Viele Christen bereiteten sich auf die
                        Wiederkunft Jesu oder den „Tag des Herrn“ vor.
                        Christliche Templer siedelten sich am Fuße des Berges
                        Karmel in Haifa an, um dort das Kommen des Herrn zu
                        erleben. Buddhisten erwarteten den „Buddha Maytrea“ (den
                        Buddha der allumfassenden Gemeinschaft) und die
                        Bhagavad-Gita der Hindus bezog sich auf den „Größten
                        Geist“ oder „Zehnten Avatar“.
                    </p>
                    <p>
                        Zur gleichen Zeit erschien im Jahr 1844 in Persien der
                        Báb als Verheißener, der ein neues Zeitalter der
                        Religion und das baldige Kommen Bahá’u’lláhs ankündigte.
                        Wenige Jahre danach verkündete Bahá’u’lláh über vier
                        Jahrzehnte hinweg in tausenden Versen und zahlreichen
                        Schriften Seine göttliche Offenbarung für die ganze
                        Menschheit.
                    </p>
                    <p>
                        <img
                            alt=""
                            src={
                                data.letterToDaughter.childImageSharp.fluid.src
                            }
                            className="aligncenter"
                        />
                    </p>
                    <p>
                        Bahá’u’lláh ebnet darin den Weg für eine neue
                        Entwicklungsstufe der Menschheit, die sowohl das
                        Wohlergehen des Einzelnen als auch das der ganzen
                        Menschheit einschließt.
                    </p>
                    <CenteredQuote referenceNumber="2">
                        Ich war nur ein Mensch wie andere und schlief auf Meinem
                        Lager – siehe, da wehten die Winde des Allherrlichen
                        über Mich und gaben Mir Kenntnis von allem, was war.
                        Diese Sache ist nicht von Mir, sondern von dem, der
                        allmächtig und allwissend ist. Und Er gebot Mir, Meine
                        Stimme zu erheben zwischen Erde und Himmel, und darum
                        überkam Mich, was jedem verständigen Menschen die Tränen
                        in die Augen treibt.
                    </CenteredQuote>
                    <p>
                        Für das Übermitteln der jüngsten Botschaft Gottes an die
                        Menschheit ertrug Bahá’u’lláh vierzig Jahre lang
                        Gefangenschaft und Verbannung. Immer mehr Menschen auf
                        der ganzen Welt folgen Seiner Botschaft. Sie sind
                        bestrebt, Seine Lehren in ihrem täglichen Leben
                        anzuwenden und sie gemeinsam mit anderen für die
                        Förderung der Einheit der Menschheit einzusetzen.
                    </p>
                </div>
                <Reference>
                    <p>
                        1. ‘Abdu’l-Bahá zitiert in: Göttliche Lebenskunst 11:5
                    </p>
                    <p>2. Bahá’u’lláh, Anspruch und Verkündigung, S. 192</p>
                </Reference>
            </div>
            <Sidebar id="sidebar" />
        </TwoColumns>
    </InnerPage>
);

export default BahaullahUndSeinAnspruch;

export const pageQuery = graphql`
    query {
        introImage: file(
            relativePath: { eq: "bahaullah-und-sein-anspruch-feature.jpg" }
        ) {
            ...fluidImage
        }
        letterToDaughter: file(relativePath: { eq: "letter-to-daughter.jpg" }) {
            ...fluidImage
        }
    }
`;
